import { useEffect } from "react";
import { useParams } from "react-router";
import { PrimaryButton, SubTitle, Title, useFetch, yearMonth } from "@viable-inc/fe-common";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../const";
import { ProductTable } from "../organisms/ProductTable";
import { BaseKeywordTable } from "../organisms/BaseKeywordTable";
import { SellerSpriteKeywordDetailDto } from "../../dtos/seller-sprite-keyword";

export const SellerSpriteDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { execFetch: fetchSellerSpriteKeyword, data, loading } = useFetch<SellerSpriteKeywordDetailDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  useEffect(() => {
    if (id) {
      fetchSellerSpriteKeyword(ENDPOINT.SELLER_SPRITE_KEYWORD(id));
    }
    // eslint-disable-next-line
  }, [id]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="space-y-8">
      <Title title="セラースプライトキーワード詳細" />
      {
        data && (
          <div>
            <SubTitle title="基本情報" />
            <p className="mb-4 font-bold text-2xl">{data.sellerSpriteKeyword.keyword}</p>
            <p className="mb-4">対象月: {yearMonth(data.sellerSpriteKeyword.targetMonth)}</p>
            <p className="mb-4">月間検索数: {data.sellerSpriteKeyword.monthlySearchVolume}</p>
            <p className="mb-4">転換率: {data.sellerSpriteKeyword.conversionRate}%</p>
            <p className="mb-4">クリック集中度: {data?.sellerSpriteKeyword.clickConcentrationRate}%</p>
            <p className="mb-4">PPCコスト率: {data?.sellerSpriteKeyword.ppcCostRate}%</p>
            <p className="mb-4">PPCコスト額: {data?.sellerSpriteKeyword.ppcCost}円</p>
          </div>
        )
      }
      {
        data && (
          <div>
            <SubTitle title="ベースキーワード" />
            <BaseKeywordTable data={data.baseKeywords} onClick={dto => navigate(`/base-keywords/${dto.id}`)} />
          </div>
        )
      }
      {
        data && (
          <div>
            <SubTitle title="アマゾン商品" />
            <ProductTable data={data.products} onClick={dto => navigate(`/products/${dto.id}`)} />
          </div>
        )
      }
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'一覧に戻る'} onClick={() => {
          navigate('/seller-sprite-keywords');
        }} />
      </div>

    </div >
  );
}
