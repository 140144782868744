export const productDisplayTypes = ['ACTIVE', 'INACTIVE', 'NO_HIT'] as const;
export type ProductDisplayType = typeof productDisplayTypes[number];
export const PRODUCT_DISPLAY_TYPE = {
  SEO: 'SEO' as ProductDisplayType,
  AD: 'AD' as ProductDisplayType,
  BOTH: 'BOTH' as ProductDisplayType,
};

export const nameByProductDisplayType = (value: string | ProductDisplayType) => {
  switch (value) {
    case PRODUCT_DISPLAY_TYPE.SEO:
      return 'SEO';
    case PRODUCT_DISPLAY_TYPE.AD:
      return '広告';
    case PRODUCT_DISPLAY_TYPE.BOTH:
      return 'SEO + 広告';
    default:
      throw new Error(`ハンドリングされていない値です。value: ${value}`);
  }
};
export const existsProductDisplayType = (value: string | ProductDisplayType) =>
  productDisplayTypes.includes(value as ProductDisplayType);
