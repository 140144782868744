import { FC } from "react";
import { useLocation } from "react-router";
import { PagingBaseDto } from "../../dtos/paging";
import { useQuery } from "@viable-inc/fe-common";
import { useNavigate } from "react-router";

type Props = {
  paging: PagingBaseDto;
}

export const Pagination: FC<Props> = ({ paging }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery(location);

  const currentPage = paging.page;
  const totalPages = Math.ceil(paging.total / paging.limit);

  return (
    <div className="flex justify-center items-center space-x-2 mt-4 text-sm">
      <button
        className={`px-3 py-1 border border-gray-300 rounded-full ${currentPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-black'}`}
        onClick={() => {
          query.set('page', (currentPage - 1).toString());
          navigate(`?${query.toString()}`);
        }}
        disabled={currentPage === 1}
      >
        前のページ
      </button>
      <span className="text-black">{currentPage} / {totalPages}</span>
      <button
        className={`px-3 py-1 border border-gray-300 rounded-full ${currentPage === totalPages ? 'text-gray-500 cursor-not-allowed' : 'text-black'}`}
        onClick={() => {
          query.set('page', (currentPage + 1).toString());
          navigate(`?${query.toString()}`);
        }}
        disabled={currentPage === totalPages}
      >
        次のページ
      </button>
    </div>
  );
}