import { FC } from "react";
import { ReverseKeywordDto } from "../../dtos/reverse-keyword";
import { datetime } from "@viable-inc/fe-common";

type Props = {
  data: ReverseKeywordDto[];
  price: number;
  onClick?: (dto: ReverseKeywordDto) => void;
}

const roundToDecimal = (target: number) => Math.floor(target * 100) / 100;

export const ReverseKeywordTable: FC<Props> = ({ data, price, onClick }) => {
  if (!data) {
    return null;
  }
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
          <tr>
            <th scope="col" className="py-3 px-6">
              キーワード
            </th>
            <th scope="col" className="py-3 px-6">
              月間検索数 / トラフィック比率
            </th>
            <th scope="col" className="py-3 px-6">
              転換率 / クリック集中度
            </th>
            <th scope="col" className="py-3 px-6">
              PPCコスト率 / 額
            </th>
            <th scope="col" className="py-3 px-6">
              登録日時
            </th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((e) => {
              return (
                <tr className={`${e.monthlySearchVolume >= 5000 && e.monthlySearchVolume <= 50000 && e.conversionRate >= 0.9 && e.clickConcentrationRate <= 40 && roundToDecimal(e.ppcCost / price * 100) <= 2 ? 'bg-amber-50' : 'bg-white'} border-b cursor-pointer`} key={e.id} onClick={() => {
                  if (onClick) {
                    onClick(e);
                  }
                }}>
                  <td className="py-4 px-6">
                    <a className="font-medium text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer" href={`https://www.amazon.co.jp/s?k=${e.keyword}`}>{e.keyword}</a>
                  </td>
                  <td className="py-4 px-6">
                    <p>{e.monthlySearchVolume}</p>
                    <p>{e.trafficRate}%</p>
                  </td>
                  <td className="py-4 px-6">
                    <p>{e.conversionRate}%</p>
                    <p>{e.clickConcentrationRate}%</p>
                  </td>
                  <td className="py-4 px-6">
                    <p>
                      {roundToDecimal(e.ppcCost / price * 100)}%
                    </p>
                    <p>
                      {e.ppcCost}円
                    </p>
                  </td>
                  <td className="py-4 px-6">
                    {datetime(e.createdAt)}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}