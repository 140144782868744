import { FC } from "react";
import dayjs from "dayjs";
import { ProductWithKeywordCountDto } from "../../dtos/product";
import { ProductWithDisplayTypeDto } from "../../dtos/product";
import { nameByProductStatus } from "../../enums/product-status";
import { nameByProductDisplayType } from "../../enums/product-display-type";
import { date, datetime, yearMonth } from "@viable-inc/fe-common";

type Props = {
  data: (ProductWithKeywordCountDto | ProductWithDisplayTypeDto)[];
  onClick?: (dto: ProductWithKeywordCountDto | ProductWithDisplayTypeDto) => void;
}

const isProductWithDisplayType = (dto: ProductWithKeywordCountDto | ProductWithDisplayTypeDto): dto is ProductWithDisplayTypeDto => {
  return 'productDisplayType' in dto;
};

const isProductWithKeywordCount = (dto: ProductWithKeywordCountDto | ProductWithDisplayTypeDto): dto is ProductWithKeywordCountDto => {
  return 'keywordCount' in dto;
};

export const ProductTable: FC<Props> = ({ data, onClick }) => {
  if (!data) {
    return null;
  }
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
          <tr>
            <th scope="col" className="py-3 px-6">
              ASIN / イメージ
            </th>
            <th scope="col" className="py-3 px-6">
              ブランド名 / 金額
            </th>
            <th scope="col" className="py-3 px-6">
              ステータス
            </th>
            <th scope="col" className="py-3 px-6">
              対象月 / 予測販売数
            </th>
            <th scope="col" className="py-3 px-6">
              カテゴリ / ランキング{data?.some(d => isProductWithDisplayType(d)) ? ' / 掲載タイプ' : ''}
            </th>
            <th scope="col" className="py-3 px-6">
              レビュー数 / 平均 / さくら度
            </th>
            <th scope="col" className="py-3 px-6">
              出品日 / 登録日時
            </th>
            {
              data?.some(d => isProductWithKeywordCount(d)) && (
                <th scope="col" className="py-3 px-6">
                  キーワード数
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((e) => {
              return (
                <tr className={`border-b ${e.reviewCount >= 10 && e.reviewAvg >= 4 && (!e.sakuraRate || e?.sakuraRate <= 10) && dayjs(e.listedAt).add(2, 'year').isAfter(dayjs()) ? 'bg-amber-50' : 'bg-white'}`} key={e.id} onClick={() => {
                  if (onClick) {
                    onClick(e);
                  }
                }}>
                  <td className="py-4 px-6">
                    <p>
                      {e.asin}
                    </p>
                    <img width={80} src={e.imageUrl} alt={e.name} />
                  </td>
                  <td className="py-4 px-6">
                    <p>
                      {e.brandName}
                    </p>
                    <p>
                      {e.price}円
                    </p>
                  </td>
                  <td className="py-4 px-6">
                    {nameByProductStatus(e.status)}
                  </td>
                  <td className="py-4 px-6">
                    <p>
                      {yearMonth(e.targetMonth)}
                    </p>
                    <p>
                      {e.forecastSalesCount}個/月
                    </p>
                    <p>
                      {isProductWithDisplayType(e) && nameByProductDisplayType(e.productDisplayType)}
                    </p>
                  </td>
                  <td className="py-4 px-6">
                    {e.category}
                    <br />
                    {e.ranking}位
                  </td>
                  <td className="py-4 px-6">
                    {e.reviewCount}件
                    <br />
                    {e.reviewAvg}
                    <br />
                    {e.sakuraRate ?? '-'}%
                  </td>
                  <td className="py-4 px-6">
                    {date(e.listedAt)}
                    <br />
                    {datetime(e.createdAt)}
                  </td>
                  {
                    data?.some(d => isProductWithKeywordCount(d)) && (
                      <td className="py-4 px-6">
                        {isProductWithKeywordCount(e) ? e.keywordCount : '-'}
                      </td>
                    )
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}