export const productStatuses = ['CREATED', 'TARGET', 'NON_TARGET'] as const;
export type ProductStatus = typeof productStatuses[number];
export const PRODUCT_STATUS = {
  CREATED: 'CREATED' as ProductStatus,
  TARGET: 'TARGET' as ProductStatus,
  NON_TARGET: 'NON_TARGET' as ProductStatus,
};

export const nameByProductStatus = (value: string | ProductStatus) => {
  switch (value) {
    case PRODUCT_STATUS.CREATED:
      return '未チェック';
    case PRODUCT_STATUS.TARGET:
      return 'リサーチ対象';
    case PRODUCT_STATUS.NON_TARGET:
      return 'リサーチ対象外';
    default:
      throw new Error(`ハンドリングされていない値です。value: ${value}`);
  }
};
export const existsProductStatus = (value: string | ProductStatus) =>
  productStatuses.includes(value as ProductStatus);
