import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SubTitle, Title, useFetch, yearMonth, date, PrimaryButton, useUpdate, SelectInput } from "@viable-inc/fe-common";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../const";
import { BaseKeywordDetailDto } from "../../dtos/base-keyword";
import { SellerSpriteKeywordTable } from "../organisms/SellerSpriteKeywordTable";
import { BASE_KEYWORD_STATUS, BaseKeywordStatus, nameByBaseKeywordStatus } from "../../enums/base-keyword-status";
import { BaseKeywordUpdateRequest } from "../../dtos/requests";

export const BaseKeywordDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState<BaseKeywordStatus | ''>('');

  const { execFetch: fetchBaseKeyword, data, loading } = useFetch<BaseKeywordDetailDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  const { execUpdate: updateBaseKeyword, submitting: updateSubmitting } = useUpdate<BaseKeywordUpdateRequest, null>({
    method: 'PUT',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: () => {
      toast.success('ステータスを変更しました。');
      fetchBaseKeyword(ENDPOINT.BASE_KEYWORD(id as string));
    }
  });


  useEffect(() => {
    if (id) {
      fetchBaseKeyword(ENDPOINT.BASE_KEYWORD(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (data) {
      setStatus(data.baseKeyword.status);
    }
    // eslint-disable-next-line
  }, [data]);


  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <Title title="ベースキーワード詳細" />
        <div className="flex items-center space-x-2">
          <SelectInput options={[...Object.values(BASE_KEYWORD_STATUS).map(v => {
            return {
              label: nameByBaseKeywordStatus(v),
              value: v
            }
          })]} value={status} onChange={v => setStatus(v as BaseKeywordStatus)} />
          <PrimaryButton text="変更する" disabled={updateSubmitting} onClick={() => {
            updateBaseKeyword({ keyword: data?.baseKeyword.keyword!, status: status as BaseKeywordStatus }, ENDPOINT.BASE_KEYWORD(id as string));
          }} />
        </div>
      </div>
      {
        data && (
          <>
            <div>
              <SubTitle title="基本情報" />
              <p className="mb-4">{data.baseKeyword.keyword}</p>
              <p className="mb-4">ステータス: {nameByBaseKeywordStatus(data.baseKeyword.status)}</p>
              <p className="mb-4">対象月: {data.baseKeyword.latestTargetMonth ? yearMonth(data.baseKeyword.latestTargetMonth) : '-'}</p>
              <p className="mb-4">対象ヒット月: {data.baseKeyword.latestFoundTargetMonth ? yearMonth(data.baseKeyword.latestFoundTargetMonth) : '-'}</p>
              <p className="mb-4">登録日時: {date(data.baseKeyword.createdAt)}</p>
            </div>
            <div>
              <SubTitle title="セラースプライトキーワード情報" />
              {
                data && (
                  <SellerSpriteKeywordTable data={data.sellerSpriteKeywords} onClick={(dto) => navigate(`/seller-sprite-keywords/${dto.id}`)} />
                )
              }
            </div>
          </>
        )
      }
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'一覧に戻る'} onClick={() => {
          navigate('/base-keywords');
        }} />
      </div>
    </div >
  );
}
