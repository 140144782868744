import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Checkbox, Modal, PrimaryButton, SelectInput, TextInput, Title, useFetch, useQuery, useUpdate, yearMonth } from "@viable-inc/fe-common";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { ProductWithKeywordCountDto } from "../../dtos/product";
import { ProductUpdateRequest } from "../../dtos/requests";
import { PagingDto } from "../../dtos/paging";
import { FaBox } from "react-icons/fa6";
import { nameByProductStatus, PRODUCT_STATUS } from "../../enums/product-status";
import { toast } from "react-toastify";
import { ENDPOINT, STORAGE } from "../../const";
import { Pagination } from "../organisms/Pagination";
import { ProductTable } from "../organisms/ProductTable";

export const ProductList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery(location);
  const [initialized, setInitialized] = useState(false);

  const [target, setTarget] = useState<ProductWithKeywordCountDto | null>(null);

  const [statusSearch, setStatusSearch] = useState('');
  // const [countFrom, setCountFrom] = useState('');
  // const [countTo, setCountTo] = useState('');
  const [forecastCountFrom, setForecastCountFrom] = useState('');
  const [forecastCountTo, setForecastCountTo] = useState('');
  const [targetMonthSearch, setTargetMonthSearch] = useState('');
  const [allDataSearch, setAllDataSearch] = useState(false);

  const [searchModalActive, setSearchModalActive] = useState(false);

  const { execFetch: fetchProducts, data, loading } = useFetch<PagingDto<ProductWithKeywordCountDto>>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  const { execUpdate: updateProduct, submitting: updateSubmitting } = useUpdate<ProductUpdateRequest, null>({
    method: 'PUT',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: async () => {
      toast.success('ステータスを変更しました。');
      setTarget(null);
      fetchProducts(`${ENDPOINT.PRODUCTS}?${query.toString()}`);
    },
  });

  useEffect(() => {
    if (!initialized) {
      const currentQuery = query.toString(); // 現在のURLのクエリパラメータ
      const previousParams = localStorage.getItem(STORAGE.PRODUCT_LIST_QUERY);

      // 現在のクエリが空の場合のみ localStorage の値を使用
      if (!currentQuery && previousParams) {
        navigate(`${window.location.pathname}?${previousParams}`);
      } else {
        // 現在のクエリを localStorage に保存
        localStorage.setItem(STORAGE.PRODUCT_LIST_QUERY, currentQuery);
      }

      setInitialized(true);
      return;
    }

    // クエリの変更時にデータ取得
    fetchProducts(`${ENDPOINT.PRODUCTS}?${query.toString()}`);
    setStatusSearch(query.get('status') || '');
    setTargetMonthSearch(query.get('target-month') || '');
    setForecastCountFrom(query.get('forecast-count-from') || '');
    setForecastCountTo(query.get('forecast-count-to') || '');
    setAllDataSearch(query.get('all-data') === 'true');
    query.set('page', '1');
    localStorage.setItem(STORAGE.PRODUCT_LIST_QUERY, query.toString());
    // eslint-disable-next-line
  }, [query, navigate, initialized, setInitialized]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <Title title="アマゾン商品一覧">
        <FaBox />
      </Title>
      {
        data && (
          <div>
            <div className="flex justify-between mb-2">
              <div>
                <div className="mb-1">
                  <p className="text-xs md:text-base">
                    [検索条件]
                  </p>
                  <p className="text-xs md:text-base">
                    {query.get('all-data') === 'true' ? 'すべての売れてる商品' : '条件をクリアした売れてる商品'}{(query.get('status') ?? '').length > 0 ? ` / ステータス: ${nameByProductStatus(query.get('status')!)}` : ''}{(query.get('target-month') ?? '').length > 0 ? ` / 対象月: ${yearMonth(query.get('target-month')!)}` : ''}{(query.get('count-from') ?? '').length > 0 ? ` / 最小キーワード数: ${query.get('count-from')}` : ''}{(query.get('count-to') ?? '').length > 0 ? ` / 最大キーワード数: ${query.get('count-to')}` : ''}{(query.get('forecast-count-from') ?? '').length > 0 ? ` / 最小予測販売数: ${query.get('forecast-count-from')}` : ''}{(query.get('forecast-count-to') ?? '').length > 0 ? ` / 最大予測販売数: ${query.get('forecast-count-to')}` : ''}
                  </p>
                </div>
              </div>
              <div>
                <Pagination paging={data} />
              </div>
            </div>
            <ProductTable data={data.data} onClick={(dto) => navigate(`/products/${dto.id}`)} />
            <div className="flex justify-end mt-2">
              <Pagination paging={data} />
            </div>
          </div>
        )
      }
      {
        target && (
          <Modal isOpen={!!target} onClose={() => setTarget(null)}>
            <div className="mb-8">
              <p>「{target.asin}({target.brandName})」のステータスを変更します。</p>
              <p>
                <span>それとも</span>
                {/* eslint-disable-next-line */}
                <a onClick={() => navigate(`/products/${target.id}`)} className="font-medium text-blue-600 hover:underline">詳細</a>
                <span>を見ますか？</span>
              </p>
            </div>
            <div className="flex items-center space-x-2">
              {
                target?.status === PRODUCT_STATUS.CREATED ? (
                  <>
                    <PrimaryButton fullWidth text="リサーチ対象にする" disabled={updateSubmitting} onClick={() => {
                      updateProduct({ status: PRODUCT_STATUS.TARGET }, ENDPOINT.PRODUCT(target.id));
                    }} />
                    <PrimaryButton fullWidth text="リサーチ対象外にする" disabled={updateSubmitting} onClick={() => {
                      updateProduct({ status: PRODUCT_STATUS.NON_TARGET }, ENDPOINT.PRODUCT(target.id));
                    }} />
                  </>
                ) : target?.status === PRODUCT_STATUS.TARGET ? (
                  <>
                      <PrimaryButton fullWidth text="リサーチ対象外にする" disabled={updateSubmitting} onClick={() => {
                      updateProduct({ status: PRODUCT_STATUS.NON_TARGET }, ENDPOINT.PRODUCT(target.id));
                    }} />
                      <PrimaryButton fullWidth text="未チェックにする" disabled={updateSubmitting} onClick={() => {
                      updateProduct({ status: PRODUCT_STATUS.CREATED }, ENDPOINT.PRODUCT(target.id));
                    }} />
                  </>
                ) : (
                  <>
                        <PrimaryButton fullWidth text="リサーチ対象にする" disabled={updateSubmitting} onClick={() => {
                      updateProduct({ status: PRODUCT_STATUS.TARGET }, ENDPOINT.PRODUCT(target.id));
                    }} />
                        <PrimaryButton fullWidth text="未チェックにする" disabled={updateSubmitting} onClick={() => {
                      updateProduct({ status: PRODUCT_STATUS.CREATED }, ENDPOINT.PRODUCT(target.id));
                    }} />
                  </>
                )
              }
            </div>
          </Modal>
        )
      }
      {
        searchModalActive && (
          <Modal isOpen={searchModalActive} onClose={() => setSearchModalActive(false)} title="検索条件">
            <div className="space-y-4">
              <div className="mt-4 space-y-2">
                <div>
                  <Checkbox label="すべての売れてる商品を表示する" checked={allDataSearch} onChange={v => setAllDataSearch(v)} />
                  <p className="text-xs text-gray-500">黄色背景の商品: 売れてる理由を分析しやすい特に狙い目の商品</p>
                  <p className="text-xs text-gray-500">レビュー平均4以上 / レビュー数10個以上 / さくら度10%以下 / 販売開始日2年以内</p>
                </div>
                <SelectInput options={[{ label: '- ステータス -', value: '' }, ...Object.values(PRODUCT_STATUS).map(v => {
                return {
                  label: nameByProductStatus(v),
                  value: v
                }
              })]} value={statusSearch} onChange={v => setStatusSearch(v)} />
              <SelectInput options={[
                { label: '- 対象月 -', value: '' },
                { label: dayjs().startOf('month').format('YYYY/M'), value: dayjs().startOf('month').toISOString() },
                { label: dayjs().subtract(1, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(1, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(2, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(2, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(3, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(3, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(4, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(4, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(5, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(5, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(6, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(6, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(7, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(7, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(8, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(8, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(9, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(9, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(10, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(10, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(11, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(11, 'month').startOf('month').toISOString() },
                ]} value={targetMonthSearch} onChange={v => setTargetMonthSearch(v)} />
                {/* <TextInput placeholder="最小キーワード数" value={countFrom} onChange={v => setCountFrom(v)} />
                <TextInput placeholder="最大キーワード数" value={countTo} onChange={v => setCountTo(v)} /> */}
                <TextInput placeholder="最小予測販売数" value={forecastCountFrom} onChange={v => setForecastCountFrom(v)} />
              <TextInput placeholder="最大予測販売数" value={forecastCountTo} onChange={v => setForecastCountTo(v)} />
              </div>
              <PrimaryButton text="検索する" fullWidth onClick={() => {
                query.set('page', '1');
                query.set('status', statusSearch);
                query.set('target-month', targetMonthSearch);
                // query.set('count-from', countFrom);
                // query.set('count-to', countTo);
                query.set('forecast-count-from', forecastCountFrom);
                query.set('forecast-count-to', forecastCountTo);
                query.set('all-data', allDataSearch.toString());
                navigate(`${window.location.pathname}?${query.toString()}`);
                setSearchModalActive(false);
              }} />
            </div>
          </Modal>
        )
      }
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'検索条件を変更する'} onClick={() => {
          setSearchModalActive(true);
        }} />
      </div>
    </div >
  );
}
