const expertsEndpointURL = (path: string) => {
  return `${process.env.REACT_APP_EXPERTS_API_URL}${path}`;
};

const amazonEndpointURL = (path: string) => {
  return `${process.env.REACT_APP_AMAZON_API_URL}${path}`;
};

export const ENDPOINT = {
  CHECK_AUTH: expertsEndpointURL('/api/v1/check-auth'),
  LOGOUT: expertsEndpointURL('/api/v1/logout'),
  BASE_KEYWORDS: amazonEndpointURL('/api/v1/base-keywords'),
  BASE_KEYWORD: (id: string) => amazonEndpointURL(`/api/v1/base-keywords/${id}`),
  SELLER_SPRITE_KEYWORDS: amazonEndpointURL('/api/v1/seller-sprite-keywords'),
  SELLER_SPRITE_KEYWORD: (id: string) => amazonEndpointURL(`/api/v1/seller-sprite-keywords/${id}`),
  PRODUCTS: amazonEndpointURL('/api/v1/products'),
  PRODUCT: (id: string) => amazonEndpointURL(`/api/v1/products/${id}`),
}

export const STORAGE = {
  BASE_KEYWORD_LIST_QUERY: 'base-keyword-list-query',
  SELLER_SPRITE_KEYWORD_LIST_QUERY: 'seller-sprite-keyword-list-query',
  PRODUCT_LIST_QUERY: 'product-list-query',
}

export const COOKIE = {
  LOGIN_REDIRECT: 'login-redirect',
  LINE_STATE: 'line-state',
  LINE_DESTINATION: 'line-destination',
}
