import { FC } from "react";
import { SellerSpriteKeywordDto, SellerSpriteKeywordWithProductCountDto, SellerSpriteKeywordWithDisplayTypeDto } from "../../dtos/seller-sprite-keyword";
import { yearMonth, datetime } from "@viable-inc/fe-common";
import { nameByProductDisplayType } from "../../enums/product-display-type";

type Props = {
  data: (SellerSpriteKeywordDto | SellerSpriteKeywordWithProductCountDto | SellerSpriteKeywordWithDisplayTypeDto)[];
  onClick?: (dto: SellerSpriteKeywordDto | SellerSpriteKeywordWithProductCountDto | SellerSpriteKeywordWithDisplayTypeDto) => void;
}

const isProductWithDisplayType = (dto: SellerSpriteKeywordDto | SellerSpriteKeywordWithProductCountDto | SellerSpriteKeywordWithDisplayTypeDto): dto is SellerSpriteKeywordWithDisplayTypeDto => {
  return 'productDisplayType' in dto;
};

const isProductWithKeywordCount = (dto: SellerSpriteKeywordDto | SellerSpriteKeywordWithProductCountDto | SellerSpriteKeywordWithDisplayTypeDto): dto is SellerSpriteKeywordWithProductCountDto => {
  return 'productCount' in dto;
};

export const SellerSpriteKeywordTable: FC<Props> = ({ data, onClick }) => {
  if (!data) {
    return null;
  }
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
          <tr>
            <th scope="col" className="py-3 px-6">
              キーワード
            </th>
            <th scope="col" className="py-3 px-6">
              月間検索数
            </th>
            <th scope="col" className="py-3 px-6">
              転換率 / クリック集中度{data?.some(d => isProductWithDisplayType(d)) ? ' / 掲載タイプ' : ''}
            </th>
            <th scope="col" className="py-3 px-6">
              PPCコスト率 / 額
            </th>
            <th scope="col" className="py-3 px-6">
              対象月 / 登録日時 / 検索日時
            </th>
            {
              data?.some(d => isProductWithKeywordCount(d)) && (
                <th scope="col" className="py-3 px-6">
                  商品数
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((e) => {
              return (
                <tr className="bg-white border-b cursor-pointer" key={e.id} onClick={() => {
                  if (onClick) {
                    onClick(e);
                  }
                }}>
                  <td className="py-4 px-6">
                    {e.keyword}
                  </td>
                  <td className="py-4 px-6">{e.monthlySearchVolume}</td>
                  <td className="py-4 px-6">
                    <p>{e.conversionRate}%</p>
                    <p>{e.clickConcentrationRate}%</p>
                    {
                      data?.some(d => isProductWithDisplayType(d)) && (
                        <td className="py-4 px-6">
                          {isProductWithDisplayType(e) ? nameByProductDisplayType(e.productDisplayType) : '-'}
                        </td>
                      )
                    }
                  </td>
                  <td className="py-4 px-6">
                    {e.ppcCostRate}%
                    <br />
                    {e.ppcCost}円
                  </td>
                  <td className="py-4 px-6">
                    {yearMonth(e.targetMonth)}
                    <br />
                    {datetime(e.createdAt)}
                    <br />
                    {e.searchedAt ? datetime(e.searchedAt) : '-'}
                  </td>
                  {
                    data?.some(d => isProductWithKeywordCount(d)) && (
                      <td className="py-4 px-6">
                        {isProductWithKeywordCount(e) ? e.productCount : '-'}
                      </td>
                    )
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}