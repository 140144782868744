import React, { useEffect, useState, ReactNode, createContext, useCallback } from 'react';
import { ENDPOINT } from '../../const';
import { AuthDto } from '../../dtos';
import { Loading } from '@viable-inc/fe-common';

interface AuthProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthDto | null>(null);

export const AuthLayout: React.FC<AuthProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthDto | null>(null);

  const fetchCheckAuth = useCallback(async () => {
    const response = await fetch(ENDPOINT.CHECK_AUTH, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // エラーレスポンス
    if (!response.ok) {
      window.location.href = `${process.env.REACT_APP_EXPERTS_BASE_URL}/amazon/login`;
      return;
    }
    const authData = await response.json();
    if (!authData) {
      window.location.href = `${process.env.REACT_APP_EXPERTS_BASE_URL}/amazon/login`;
      return;
    }
    if (!['hatakeyama@viable.jp', 'ohya@viable.jp', 'reoreo1226@gmail.com'].includes(authData.user.email)) {
      window.location.href = `${process.env.REACT_APP_EXPERTS_BASE_URL}/amazon/login`;
      return;
    }
    setAuth(authData);
  }, [setAuth]);

  useEffect(() => {
    fetchCheckAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!auth) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <Loading />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={auth}>
      <main className="container mx-auto">
        {children}
      </main>
    </AuthContext.Provider>
  );
};
