import { FC } from "react";
import { BaseKeywordDto } from "../../dtos/base-keyword";
import { nameByBaseKeywordStatus } from "../../enums/base-keyword-status";
import { yearMonth, datetime } from "@viable-inc/fe-common";

type Props = {
  data: BaseKeywordDto[];
  onClick?: (dto: BaseKeywordDto) => void;
}

export const BaseKeywordTable: FC<Props> = ({ data, onClick }) => {
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="stikcy top-0 text-xs text-gray-700 uppercase bg-gray-100">
          <tr>
            <th scope="col" className="py-3 px-6">
              ベースキーワード
            </th>
            <th scope="col" className="py-3 px-6">
              ステータス
            </th>
            <th scope="col" className="py-3 px-6">
              最新対象月 / 最新ヒット月
            </th>
            <th scope="col" className="py-3 px-6">
              登録日時
            </th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((e) => {
              return (
                <tr className="bg-white border-b cursor-pointer" key={e.id} onClick={() => {
                  if (onClick) {
                    onClick(e);
                  }
                }}>
                  <td className="py-4 px-6">{e.keyword}</td>
                  <td className="py-4 px-6">{nameByBaseKeywordStatus(e.status)}</td>
                  <td className="py-4 px-6">
                    {e.latestTargetMonth ? yearMonth(e.latestTargetMonth) : '-'}
                    <br />
                    {e.latestFoundTargetMonth ? yearMonth(e.latestFoundTargetMonth) : '-'}
                  </td>
                  <td className="py-4 px-6">
                    {datetime(e.createdAt)}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}