import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SubTitle, SelectInput, Title, useFetch, useUpdate, yearMonth, date, PrimaryButton, SecondaryButton } from "@viable-inc/fe-common";
import { useNavigate } from "react-router";
import { ProductUpdateRequest } from "../../dtos/requests";
import { FaBox } from "react-icons/fa6";
import { nameByProductStatus, PRODUCT_STATUS, ProductStatus } from "../../enums/product-status";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../const";
import { ProductTable } from "../organisms/ProductTable";
import { ProductDetailDto } from "../../dtos/product";
import { nameByProductDisplayType } from "../../enums/product-display-type";
import { ReverseKeywordTable } from "../organisms/ReverseKeywordTable";

export const ProductDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [status, setStatus] = useState<ProductStatus | ''>('');

  const { execFetch: fetchProduct, data, loading } = useFetch<ProductDetailDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  const { execUpdate: updateProduct, submitting } = useUpdate<ProductUpdateRequest, null>({
    method: 'PUT',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: () => {
      toast.success('ステータスを変更しました。');
      if (id) {
        fetchProduct(ENDPOINT.PRODUCT(id));
      }
    }
  });


  useEffect(() => {
    if (id) {
      fetchProduct(ENDPOINT.PRODUCT(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (data) {
      setStatus(data.product.status);
    }
    // eslint-disable-next-line
  }, [data]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <Title title="アマゾン商品詳細">
          <FaBox />
        </Title>
        <div className="flex items-center space-x-2">
          <SelectInput options={[...Object.values(PRODUCT_STATUS).map(v => {
            return {
              label: nameByProductStatus(v),
              value: v
            }
          })]} value={status} onChange={v => setStatus(v as ProductStatus)} />
          <PrimaryButton text="変更する" disabled={submitting} onClick={() => {
            updateProduct({ status: status as ProductStatus }, ENDPOINT.PRODUCT(id as string));
          }} />
        </div>
      </div>
      {
        data && (
          <>
            <div className="space-y-4">
              <SubTitle title="基本情報" />
              <p>
                <a className="font-medium text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer" href={`https://www.amazon.co.jp/dp/${data.product.asin}`}>{data.product.name}</a>
              </p>
              <img width={500} src={data.product.imageUrl} alt={data.product.name} />
              <p>ASIN: {data.product.asin}</p>
              <p>ブランド: {data.product.brandName}</p>
              <p>価格: {data.product.price}円</p>
              <p>対象月: {yearMonth(data.product.targetMonth)}</p>
              <p>レビュー数 / 平均: {data.product.reviewCount}個 / {data.product.reviewAvg}</p>
              <p>カテゴリ / ランキング: {data.product.category} / {data.product.ranking}位</p>
              <p>販売予測数: {data?.product.forecastSalesCount}個/月</p>
              <p>さくら度: {data.product.sakuraRate ?? '-'}%</p>
              <p>キーワード数: {data.keywords.length}</p>
              <p>出品日付: {date(data.product.listedAt)}</p>
            </div>
            <div>
              <SubTitle title="キーワード情報" />
              {
                data.keywords.map((k) => {
                  return <div key={k.keyword.id}>
                    <p className="text-xl">
                      <a className="font-medium text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer" href={`https://www.amazon.co.jp/s?k=${k.keyword.keyword}`}>{k.keyword.keyword}</a>
                      <span className="px-1">-</span>
                      {nameByProductDisplayType(k.keyword.productDisplayType)}</p>
                    <p>1ページ目で売れてる商品数: {k.products.length}</p>
                    <ProductTable data={k.products} onClick={(dto) => navigate(`/products/${dto.id}`)} />
                  </div>
                })
              }
            </div>
            <div>
              <div className="flex space-x-2 items-center mb-2">
                <SubTitle title="逆引きキーワード情報" />
                <SecondaryButton text="セラースプライトを見る" onClick={() => {
                  window.open(`https://www.sellersprite.com/v3/keyword-reverse/?q=${data.product.asin}&marketId=6&date=&badges=`, '_blank');
                }} />
              </div>
              {
                data && (
                  <ReverseKeywordTable data={data.reverseKeywords} price={data.product.price} />
                )
              }
            </div>
          </>
        )
      }
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'一覧に戻る'} onClick={() => {
          navigate('/products');
        }} />
      </div>
    </div >
  );
}
