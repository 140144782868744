import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuButton, Modal, SecondaryButton } from '@viable-inc/fe-common';
import { Outlet } from 'react-router-dom';
import CopyButton from '../atoms/CopyButton';
import { logout } from '../../firebase';

export const AuthCommonLayout: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className='px-2 py-8'>
      <div className="z-10 fixed bottom-20 right-2 sm:right-4">
        <div className='flex flex-col space-y-2'>
          <MenuButton onClick={() => setIsMenuOpen(true)} />
          <CopyButton />
        </div>
      </div>
      <Modal isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} title={"メニュー"}>
        <div className='flex flex-col space-y-2'>
          <SecondaryButton text='アマゾン商品一覧' onClick={() => {
            navigate(`/products`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='セラースプライトキーワード一覧' onClick={() => {
            navigate(`/seller-sprite-keywords`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='ベースキーワード一覧' onClick={() => {
            navigate(`/base-keywords`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='ログアウト' onClick={async () => {
            await logout(() => {
              window.location.href = process.env.REACT_APP_EXPERTS_API_URL + '/amazon/login';
            });
          }} />
        </div>
      </Modal>
      <div className='mb-20'>
        <Outlet />
      </div>
    </div>
  );
};
