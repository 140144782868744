export const baseKeywordStatuses = ['ACTIVE', 'INACTIVE', 'NO_HIT'] as const;
export type BaseKeywordStatus = typeof baseKeywordStatuses[number];
export const BASE_KEYWORD_STATUS = {
  ACTIVE: 'ACTIVE' as BaseKeywordStatus,
  INACTIVE: 'INACTIVE' as BaseKeywordStatus,
  NO_HIT: 'NO_HIT' as BaseKeywordStatus,
};

export const nameByBaseKeywordStatus = (value: string | BaseKeywordStatus) => {
  switch (value) {
    case BASE_KEYWORD_STATUS.ACTIVE:
      return '有効';
    case BASE_KEYWORD_STATUS.INACTIVE:
      return '無効';
    case BASE_KEYWORD_STATUS.NO_HIT:
      return 'HITなし';
    default:
      throw new Error(`ハンドリングされていない値です。value: ${value}`);
  }
};
export const existsBaseKeywordStatus = (value: string | BaseKeywordStatus) =>
  baseKeywordStatuses.includes(value as BaseKeywordStatus);
