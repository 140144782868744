import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, BrowserRouter } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthLayout } from './components/templates/AuthLayout';
import { AuthCommonLayout } from './components/templates/AuthCommonLayout';
import { ProductList } from './components/pages/ProductList';
import { BaseKeywordList } from './components/pages/BaseKeywordList';
import { SellerSpriteList } from './components/pages/SellerSpriteKeywordList';
import { ProductDetail } from './components/pages/ProductDetail';
import { SellerSpriteDetail } from './components/pages/SellerSpriteKeywordDetail';
import { BaseKeywordDetail } from './components/pages/BaseKeywordDetail';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        toastClassName={() =>
          "text-black font-medium bg-white p-2"
        }
        closeButton={false}
      />
      <Routes>
        <Route
          path="/"
          element={
            <AuthLayout>
              <AuthCommonLayout />
            </AuthLayout>
          }
        >
          <Route path="/" element={<Navigate to="/products" />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<ProductDetail />} />
          <Route path="/seller-sprite-keywords" element={<SellerSpriteList />} />
          <Route path="/seller-sprite-keywords/:id" element={<SellerSpriteDetail />} />
          <Route path="/base-keywords" element={<BaseKeywordList />} />
          <Route path="/base-keywords/:id" element={<BaseKeywordDetail />} />
        </Route>
        <Route path="*" element={<Navigate to="/products" />} />
      </Routes>
    </BrowserRouter>
  );
};

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;