import { useEffect, useState } from "react";
import { Title, useFetch, TextInput, PrimaryButton, SelectInput, Modal, useQuery, yearMonth } from "@viable-inc/fe-common";
import { Pagination } from "../organisms/Pagination";
import { PagingDto } from "../../dtos/paging";
import { ENDPOINT, STORAGE } from "../../const";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { SellerSpriteKeywordWithProductCountDto } from "../../dtos/seller-sprite-keyword";
import { SellerSpriteKeywordTable } from "../organisms/SellerSpriteKeywordTable";

export const SellerSpriteList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery(location);
  const [initialized, setInitialized] = useState(false);

  const [keywordSearch, setKeywordSearch] = useState('');
  const [targetMonthSearch, setTargetMonthSearch] = useState('');

  const [searchModalActive, setSearchModalActive] = useState(false);

  const { execFetch: fetchSellerSpriteKeywords, data, loading } = useFetch<PagingDto<SellerSpriteKeywordWithProductCountDto>>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  useEffect(() => {
    if (!initialized) {
      const previosParams = localStorage.getItem(STORAGE.SELLER_SPRITE_KEYWORD_LIST_QUERY);
      if (previosParams && previosParams.length > 0) {
        navigate(`${window.location.pathname}?${previosParams}`);
      }
      setInitialized(true);
      return;
    }

    fetchSellerSpriteKeywords(`${ENDPOINT.SELLER_SPRITE_KEYWORDS}?${query.toString()}`);
    setKeywordSearch(query.get('keyword') || '');
    setTargetMonthSearch(query.get('target-month') || '');

    query.set('page', '1');
    localStorage.setItem(STORAGE.SELLER_SPRITE_KEYWORD_LIST_QUERY, query.toString());
    // eslint-disable-next-line
  }, [query, navigate, initialized, setInitialized]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <Title title="セラースプライトキーワード一覧" />
      {
        data && (
          <div>
            <div className="flex justify-between mb-2">
              <div>
                <div className="mb-1">
                  <p className="text-xs md:text-base">
                    [検索条件]
                  </p>
                  <p className="text-xs md:text-base">
                    キーワード: {query.get('keyword') && query.get('keyword')!.length > 0 ? query.get('keyword') : '-'} / 対象月: {query.get('target-month') && query.get('target-month')!.length > 0 ? yearMonth(query.get('target-month')!) : '-'}
                  </p>
                </div>
              </div>
              <div>
                <Pagination paging={data} />
              </div>
            </div>
            <SellerSpriteKeywordTable data={data.data} onClick={(dto) => navigate(`/seller-sprite-keywords/${dto.id}`)} />
            <div className="flex justify-end mt-2">
              <Pagination paging={data} />
            </div>
          </div>
        )
      }
      {
        searchModalActive && (
          <Modal isOpen={searchModalActive} onClose={() => setSearchModalActive(false)} title="検索条件">
            <div className="space-y-4">
              <div className="mt-4 space-y-2">
                <TextInput placeholder="キーワード" value={keywordSearch} onChange={v => setKeywordSearch(v)} />
              <div className="mb-2" />
              <SelectInput options={[
                { label: '- 対象月 -', value: '' },
                { label: dayjs().startOf('month').format('YYYY/M'), value: dayjs().startOf('month').toISOString() },
                { label: dayjs().subtract(1, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(1, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(2, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(2, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(3, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(3, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(4, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(4, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(5, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(5, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(6, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(6, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(7, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(7, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(8, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(8, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(9, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(9, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(10, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(10, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(11, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(11, 'month').startOf('month').toISOString() },
              ]} value={targetMonthSearch} onChange={v => setTargetMonthSearch(v)} />
              </div>
              <PrimaryButton fullWidth text="検索する" onClick={() => {
                query.set('page', '1');
                query.set('keyword', keywordSearch);
                query.set('target-month', targetMonthSearch);
                navigate(`${window.location.pathname}?${query.toString()}`);
                setSearchModalActive(false);
              }} />            
            </div>
          </Modal>
        )
      }
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'検索条件を変更する'} onClick={() => {
          setSearchModalActive(true);
        }} />
      </div>
    </div >
  );
}
