import { useEffect, useState } from "react";
import { Title, useFetch, useUpdate, TextInput, PrimaryButton, SelectInput, Modal, useQuery, yearMonth } from "@viable-inc/fe-common";
import { Pagination } from "../organisms/Pagination";
import { useNavigate, useLocation } from "react-router";
import { BaseKeywordDto } from "../../dtos/base-keyword";
import { PagingDto } from "../../dtos/paging";
import { ENDPOINT, STORAGE } from "../../const";
import { BaseKeywordCreateRequest, BaseKeywordUpdateRequest } from "../../dtos/requests";
import { BASE_KEYWORD_STATUS, nameByBaseKeywordStatus } from "../../enums/base-keyword-status";
import { BaseKeywordTable } from "../organisms/BaseKeywordTable";
import dayjs from "dayjs";
import { FaBox } from "react-icons/fa6";
import { toast } from "react-toastify";

export const BaseKeywordList = () => {
  const location = useLocation();
  const query = useQuery(location);
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);


  const [keyword, setKeyword] = useState('');

  const [keywordSearch, setKeywordSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [latestTargetMonthSearch, setLatestTargetMonthSearch] = useState('');
  const [latestFoundTargetMonthSearch, setLatestFoundTargetMonthSearch] = useState('');

  const [target, setTarget] = useState<BaseKeywordDto | null>(null);
  const [searchModalActive, setSearchModalActive] = useState(false);

  const { execFetch: fetchBaseKeywords, data, loading } = useFetch<PagingDto<BaseKeywordDto>>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  const { execUpdate: createBaseKeyword, submitting } = useUpdate<BaseKeywordCreateRequest, null>({
    method: 'POST',
    path: ENDPOINT.BASE_KEYWORDS,
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: () => {
      toast.success('登録しました。');
      setKeyword('');
      fetchBaseKeywords(`${ENDPOINT.BASE_KEYWORDS}?${query.toString()}`);
    }
  });

  const { execUpdate: updateBaseKeyword, submitting: updateSubmitting } = useUpdate<BaseKeywordUpdateRequest, null>({
    method: 'PUT',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: () => {
      toast.success('ステータスを変更しました。');
      setTarget(null);
      fetchBaseKeywords(`${ENDPOINT.BASE_KEYWORDS}?${query.toString()}`);
    }
  });

  useEffect(() => {
    if (!initialized) {
      const previosParams = localStorage.getItem(STORAGE.BASE_KEYWORD_LIST_QUERY);
      if (previosParams && previosParams.length > 0) {
        navigate(`${window.location.pathname}?${previosParams}`);
      }
      setInitialized(true);
      return;
    }

    fetchBaseKeywords(`${ENDPOINT.BASE_KEYWORDS}?${query.toString()}`);
    setKeywordSearch(query.get('keyword') || '');
    setStatusSearch(query.get('status') || '');
    setLatestTargetMonthSearch(query.get('latest-target-month') || '');
    setLatestFoundTargetMonthSearch(query.get('latest-found-target-month') || '');

    query.set('page', '1');
    localStorage.setItem(STORAGE.BASE_KEYWORD_LIST_QUERY, query.toString());
    // eslint-disable-next-line
  }, [query, navigate, initialized, setInitialized]);


  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <Title title="ベースキーワード一覧">
          <FaBox />
        </Title>
        <div className="flex items-center gap-2">
          <div style={{ width: '220px' }}>
            <TextInput placeholder="登録したいキーワード" value={keyword} onChange={v => setKeyword(v)} />
          </div>
          <PrimaryButton text="登録する" disabled={submitting} onClick={() => createBaseKeyword({ keyword: keyword })} />
        </div>
      </div>
      {
        data && (
          <div>
            <div className="flex justify-between mb-2">
              <div>
                <div className="mb-1">
                  <p className="text-xs md:text-base">
                    [検索条件]
                  </p>
                  <p className="text-xs md:text-base">
                    キーワード: {query.get('keyword') && query.get('keyword')!.length > 0 ? query.get('keyword') : '-'} / ステータス: {query.get('status') && query.get('status')!.length > 0 ? nameByBaseKeywordStatus(query.get('status')!) : '-'} / 最新対象月: {query.get('latest-target-month') && query.get('latest-target-month')!.length > 0 ? yearMonth(query.get('latest-target-month')!) : '-'} / 最新ヒット月: {query.get('latest-found-target-month') && query.get('latest-found-target-month')!.length > 0 ? yearMonth(query.get('latest-found-target-month')!) : '-'}
                  </p>
                </div>
              </div>
              <div>
                <Pagination paging={data} />
              </div>
            </div>
            <BaseKeywordTable data={data.data} onClick={(dto) => navigate(`/base-keywords/${dto.id}`)} />
            <div className="flex justify-end mt-2">
              <Pagination paging={data} />
            </div>
          </div>
        )
      }
      {
        target && (
          <Modal isOpen={!!target} onClose={() => setTarget(null)}>
            <div className="mb-8">
              <p>「{target.keyword}」のステータスを変更します。</p>
              <p>
                <span>それとも</span>
                {/* eslint-disable-next-line */}
                <a onClick={() => navigate(`/base-keywords/${target.id}`)} className="font-medium text-blue-600 hover:underline">詳細</a>
                <span>を見ますか？</span>
              </p>
            </div>
            <div className="flex items-center space-x-2">
              {
                target?.status === BASE_KEYWORD_STATUS.ACTIVE ? (
                  <>
                    <PrimaryButton fullWidth text="無効にする" disabled={updateSubmitting} onClick={() => {
                      updateBaseKeyword({ keyword: target.keyword, status: BASE_KEYWORD_STATUS.INACTIVE }, ENDPOINT.BASE_KEYWORD(target.id));
                    }} />
                  </>
                ) : target?.status === BASE_KEYWORD_STATUS.INACTIVE ? (
                  <>
                      <PrimaryButton fullWidth text="有効にする" disabled={updateSubmitting} onClick={() => {
                      updateBaseKeyword({ keyword: target.keyword, status: BASE_KEYWORD_STATUS.ACTIVE }, ENDPOINT.BASE_KEYWORD(target.id));
                    }} />
                  </>

                ) : (
                  <>
                        <PrimaryButton fullWidth text="有効にする" disabled={updateSubmitting} onClick={() => {
                      updateBaseKeyword({ keyword: target.keyword, status: BASE_KEYWORD_STATUS.ACTIVE }, ENDPOINT.BASE_KEYWORD(target.id));
                    }} />
                        <PrimaryButton fullWidth text="無効にする" disabled={updateSubmitting} onClick={() => {
                      updateBaseKeyword({ keyword: target.keyword, status: BASE_KEYWORD_STATUS.INACTIVE }, ENDPOINT.BASE_KEYWORD(target.id));
                    }} />
                  </>
                )
              }
            </div>
          </Modal>
        )
      }
      {
        searchModalActive && (
          <Modal isOpen={searchModalActive} onClose={() => setSearchModalActive(false)} title="検索条件">
            <div className="space-y-4">
              <div className="mt-4 space-y-2">
                <TextInput placeholder="キーワード" value={keywordSearch} onChange={v => setKeywordSearch(v)} />
              <SelectInput options={[{ label: '- ステータス -', value: '' }, ...Object.values(BASE_KEYWORD_STATUS).map(v => {
                return {
                  label: nameByBaseKeywordStatus(v),
                  value: v
                }
              })]} value={statusSearch} onChange={v => setStatusSearch(v)} />
              <SelectInput options={[
                { label: '- 最新対象月 -', value: '' },
                { label: dayjs().startOf('month').format('YYYY/M'), value: dayjs().startOf('month').toISOString() },
                { label: dayjs().subtract(1, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(1, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(2, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(2, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(3, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(3, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(4, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(4, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(5, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(5, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(6, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(6, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(7, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(7, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(8, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(8, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(9, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(9, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(10, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(10, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(11, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(11, 'month').startOf('month').toISOString() },
                ]} value={latestTargetMonthSearch} onChange={v => setLatestTargetMonthSearch(v)} />
              <SelectInput options={[
                { label: '- 最新ヒット月 -', value: '' },
                { label: dayjs().startOf('month').format('YYYY/M'), value: dayjs().startOf('month').toISOString() },
                { label: dayjs().subtract(1, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(1, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(2, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(2, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(3, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(3, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(4, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(4, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(5, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(5, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(6, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(6, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(7, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(7, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(8, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(8, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(9, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(9, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(10, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(10, 'month').startOf('month').toISOString() },
                { label: dayjs().subtract(11, 'month').startOf('month').format('YYYY/M'), value: dayjs().subtract(11, 'month').startOf('month').toISOString() },
              ]} value={latestFoundTargetMonthSearch} onChange={v => setLatestFoundTargetMonthSearch(v)} />
              </div>
              <PrimaryButton text="検索する" fullWidth onClick={() => {
                query.set('page', '1');
                query.set('keyword', keywordSearch);
                query.set('status', statusSearch);
                query.set('latest-target-month', latestTargetMonthSearch);
                query.set('latest-found-target-month', latestFoundTargetMonthSearch);
                navigate(`${window.location.pathname}?${query.toString()}`);
                setSearchModalActive(false);
              }} />
            </div>
          </Modal>
        )
      }
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'検索条件を変更する'} onClick={() => {
          setSearchModalActive(true);
        }} />
      </div>
    </div >
  );
}
